import list_group_1 from './list_group_1';
import list_group_2 from './list_group_2';
import list_group_3 from './list_group_3';
import list_group_4 from './list_group_4';
import list_group_5 from './list_group_5';
import list_group_6 from './list_group_6';

const list_group = {
  list_group_1,
  list_group_2,
  list_group_3,
  list_group_4,
  list_group_5,
  list_group_6
}

export default list_group;
