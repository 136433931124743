import media_1 from './media_1';
import media_2 from './media_2';
import media_3 from './media_3';

const media = {
  media_1,
  media_2,
  media_3
}

export default media;
