import React, { Component } from 'react';
import { BDiv } from 'bootstrap-4-react';

export default class App extends Component {
  render() {
    return (
      <BDiv bg="primary" text="center white" p="5">Text inside BDiv</BDiv>
    )
  }
}
