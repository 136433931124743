import form_1 from './form_1';
import form_2 from './form_2';
import form_3 from './form_3';
import form_4 from './form_4';
import form_5 from './form_5';
import form_6 from './form_6';
import form_7 from './form_7';
import form_8 from './form_8';
import form_9 from './form_9';
import form_10 from './form_10';
import form_11 from './form_11';
import form_12 from './form_12';

const form = {
  form_1,
  form_2,
  form_3,
  form_4,
  form_5,
  form_6,
  form_7,
  form_8,
  form_9,
  form_10,
  form_11,
  form_12
}

export default form;
