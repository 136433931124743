import collapse_1 from './collapse_1';
import collapse_2 from './collapse_2';
import collapse_3 from './collapse_3';

const collapse = {
  collapse_1,
  collapse_2,
  collapse_3
}

export default collapse;
