import progress_1 from './progress_1';
import progress_2 from './progress_2';
import progress_3 from './progress_3';
import progress_4 from './progress_4';
import progress_5 from './progress_5';
import progress_6 from './progress_6';
import progress_7 from './progress_7';

const progress = {
  progress_1,
  progress_2,
  progress_3,
  progress_4,
  progress_5,
  progress_6,
  progress_7
}

export default progress;
