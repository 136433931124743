import colors_1 from './colors_1';
import colors_2 from './colors_2';
import colors_3 from './colors_3';

const colors = {
  colors_1,
  colors_2,
  colors_3
}

export default colors;
