import flex_1 from './flex_1';
import flex_2 from './flex_2';
import flex_3 from './flex_3';
import flex_4 from './flex_4';
import flex_5 from './flex_5';
import flex_6 from './flex_6';
import flex_7 from './flex_7';
import flex_8 from './flex_8';
import flex_9 from './flex_9';
import flex_10 from './flex_10';
import flex_11 from './flex_11';
import flex_12 from './flex_12';
import flex_13 from './flex_13';

const flex = {
  flex_1,
  flex_2,
  flex_3,
  flex_4,
  flex_5,
  flex_6,
  flex_7,
  flex_8,
  flex_9,
  flex_10,
  flex_11,
  flex_12,
  flex_13
}

export default flex;
