import React, { Component } from 'react';
import { Lead } from 'bootstrap-4-react';

export default class App extends Component {
  render() {
    return (
      <Lead>Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est non commodo luctus.</Lead>
    )
  }
}
