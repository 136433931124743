import display_util_1 from './display_util_1';
import display_util_2 from './display_util_2';
import display_util_3 from './display_util_3';

const display_util = {
  display_util_1,
  display_util_2,
  display_util_3
}

export default display_util;
