import nav_1 from './nav_1';
import nav_2 from './nav_2';
import nav_3 from './nav_3';
import nav_4 from './nav_4';
import nav_5 from './nav_5';
import nav_6 from './nav_6';
import nav_7 from './nav_7';
import nav_8 from './nav_8';
import nav_9 from './nav_9';
import nav_10 from './nav_10';
import nav_11 from './nav_11';

const nav = {
  nav_1,
  nav_2,
  nav_3,
  nav_4,
  nav_5,
  nav_6,
  nav_7,
  nav_8,
  nav_9,
  nav_10,
  nav_11
}

export default nav;
