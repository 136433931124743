import grid_1 from './grid_1';
import grid_2 from './grid_2';
import grid_3 from './grid_3';
import grid_4 from './grid_4';
import grid_5 from './grid_5';
import grid_6 from './grid_6';
import grid_7 from './grid_7';
import grid_8 from './grid_8';
import grid_9 from './grid_9';
import grid_10 from './grid_10';
import grid_11 from './grid_11';
import grid_12 from './grid_12';
import grid_13 from './grid_13';
import grid_14 from './grid_14';
import grid_15 from './grid_15';
import grid_16 from './grid_16';
import grid_17 from './grid_17';

const grid = {
  grid_1,
  grid_2,
  grid_3,
  grid_4,
  grid_5,
  grid_6,
  grid_7,
  grid_8,
  grid_9,
  grid_10,
  grid_11,
  grid_12,
  grid_13,
  grid_14,
  grid_15,
  grid_16,
  grid_17
}

export default grid;
