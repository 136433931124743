import button_group_1 from './button_group_1';
import button_group_2 from './button_group_2';
import button_group_3 from './button_group_3';
import button_group_4 from './button_group_4';
import button_group_5 from './button_group_5';
import button_group_6 from './button_group_6';

const button_group = {
  button_group_1,
  button_group_2,
  button_group_3,
  button_group_4,
  button_group_5,
  button_group_6
}

export default button_group
