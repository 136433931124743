import button_1 from './button_1';
import button_2 from './button_2';
import button_3 from './button_3';
import button_4 from './button_4';
import button_5 from './button_5';
import button_6 from './button_6';
import button_7 from './button_7';
import button_8 from './button_8';
import button_9 from './button_9';

const button = {
  button_1,
  button_2,
  button_3,
  button_4,
  button_5,
  button_6,
  button_7,
  button_8,
  button_9
};

export default button;
