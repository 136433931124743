import sizing_1 from './sizing_1';
import sizing_2 from './sizing_2';
import sizing_3 from './sizing_3';
import sizing_4 from './sizing_4';

const sizing = {
  sizing_1,
  sizing_2,
  sizing_3,
  sizing_4
}

export default sizing;
