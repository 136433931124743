import pagination_1 from './pagination_1';
import pagination_2 from './pagination_2';
import pagination_3 from './pagination_3';
import pagination_4 from './pagination_4';
import pagination_5 from './pagination_5';
import pagination_6 from './pagination_6';
import pagination_7 from './pagination_7';
import pagination_8 from './pagination_8';

const pagination = {
  pagination_1,
  pagination_2,
  pagination_3,
  pagination_4,
  pagination_5,
  pagination_6,
  pagination_7,
  pagination_8
}

export default pagination;
