import carousel_1 from './carousel_1';
import carousel_2 from './carousel_2';
import carousel_3 from './carousel_3';
import carousel_4 from './carousel_4';
import carousel_5 from './carousel_5';

const carousel = {
  carousel_1,
  carousel_2,
  carousel_3,
  carousel_4,
  carousel_5
}

export default carousel;
