import border_1 from './border_1';
import border_2 from './border_2';
import border_3 from './border_3';
import border_4 from './border_4';

const border = {
  border_1,
  border_2,
  border_3,
  border_4
}

export default border;
