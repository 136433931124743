import input_group_1 from './input_group_1';
import input_group_2 from './input_group_2';
import input_group_3 from './input_group_3';
import input_group_4 from './input_group_4';
import input_group_5 from './input_group_5';
import input_group_6 from './input_group_6';
import input_group_7 from './input_group_7';
import input_group_8 from './input_group_8';
import input_group_9 from './input_group_9';
import input_group_10 from './input_group_10';

const input_group = {
  input_group_1,
  input_group_2,
  input_group_3,
  input_group_4,
  input_group_5,
  input_group_6,
  input_group_7,
  input_group_8,
  input_group_9,
  input_group_10
}

export default input_group
