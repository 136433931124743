import dropdown_1 from './dropdown_1';
import dropdown_2 from './dropdown_2';
import dropdown_3 from './dropdown_3';
import dropdown_4 from './dropdown_4';
import dropdown_5 from './dropdown_5';
import dropdown_6 from './dropdown_6';

const dropdown = {
  dropdown_1,
  dropdown_2,
  dropdown_3,
  dropdown_4,
  dropdown_5,
  dropdown_6
}

export default dropdown;
