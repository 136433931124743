import modal_1 from './modal_1';
import modal_2 from './modal_2';
import modal_3 from './modal_3';
import modal_4 from './modal_4';

const modal = {
  modal_1,
  modal_2,
  modal_3,
  modal_4
}

export default modal;
