import navbar_1 from './navbar_1';
import navbar_2 from './navbar_2';
import navbar_3 from './navbar_3';
import navbar_4 from './navbar_4';
import navbar_5 from './navbar_5';
import navbar_6 from './navbar_6';
import navbar_7 from './navbar_7';
import navbar_8 from './navbar_8';
import navbar_9 from './navbar_9';

const navbar = {
  navbar_1,
  navbar_2,
  navbar_3,
  navbar_4,
  navbar_5,
  navbar_6,
  navbar_7,
  navbar_8,
  navbar_9
}

export default navbar;
