import popover_1 from './popover_1';
import popover_2 from './popover_2';
import popover_3 from './popover_3';

const popover = {
  popover_1,
  popover_2,
  popover_3
}

export default popover;
