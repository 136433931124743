import badge_1 from './badge_1';
import badge_2 from './badge_2';
import badge_3 from './badge_3';

const badge = {
  badge_1,
  badge_2,
  badge_3
};

export default badge;
