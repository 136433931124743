import card_1 from './card_1';
import card_2 from './card_2';
import card_3 from './card_3';
import card_4 from './card_4';
import card_5 from './card_5';
import card_6 from './card_6';
import card_7 from './card_7';
import card_8 from './card_8';
import card_9 from './card_9';
import card_10 from './card_10';

const card = {
  card_1,
  card_2,
  card_3,
  card_4,
  card_5,
  card_6,
  card_7,
  card_8,
  card_9,
  card_10
}

export default card
